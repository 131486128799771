import React from "react";
import { graphql, Link, useStaticQuery, navigate} from "gatsby";
import BackgroundImage from "gatsby-background-image";



//Flag
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/scss/react-flags-select.scss';

//Styles
import "./footer.scss";

//ICONS
import { RiMailLine } from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa";
import { GrTwitter } from "react-icons/gr";
import { FaLinkedinIn } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";

//SVG
import LogoWhite from "./../images/logo-white.svg";


const Footer = ({lang}) => {


    let data = {
        "es" : {
            "privacity" : {
                "name" : "Aviso de privacidad",
                "link" : "/privacidad/"
            },
            "accesibility" : {
                "name" : "Accesibilidad",
                "link" : "/accesibilidad/"
            }
        },
        "en": {
            "privacity": {
                "name": "Privacy Policy",
                "link": "/en/privacy/"
            },
            "accesibility": {
                "name": "Accesibility",
                "link": "/en/accesibility/"
            }
        }
    }

    //Image
    const image = useStaticQuery(graphql`
        query {
            footer: file(relativePath: { eq: "consultoria_integral_estructurada_CIE.jpg" }) {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            footerLogo: file(relativePath: { eq: "cie-wn.png" }) {
                childImageSharp {
                    fluid(maxWidth: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    const changeLanguage = (code) => {
        switch (code) {
            case "MX":
                navigate("/")
                break;
            case "US":
                navigate("/en/")
                break;
        }
    }

    return(
        <footer>
            <BackgroundImage fluid={image.footer.childImageSharp.fluid} className="img" >
                    <div className="overlay"></div>
                    <ul className="social">
                        <li> <a className="link" href="mailto:contacto@cienegocios.com" aria-label="Mail" > <RiMailLine /> </a> </li>
                        <li> <a className="link" href="https://www.facebook.com/cienegociosmx/" aria-label="Facebook"> <FaFacebookF /> </a> </li>
                        <li> <a className="link" href="https://twitter.com/CIE38892629" aria-label="Twitter"> <GrTwitter /> </a> </li>
                        <li> <a className="link" href="https://www.linkedin.com/company/cienegociosmx" aria-label="LinkedIn"> <FaLinkedinIn /> </a> </li>
                        <li> <a className="link" href="tel:+528117434207" aria-label="Phone"> <FaPhone /> </a> </li>
                    </ul>
                    <div className="logo-container">
                        <img className="logo" src={LogoWhite} width="180" height="100" alt="Logo CIE" loading="lazy" />
                    </div>
                    <ul className="pages">
                        <li> <Link className="link" to={data[lang].privacity.link}> {data[lang].privacity.name} </Link> </li>
                        <li> <Link className="link" to={data[lang].accesibility.link}> {data[lang].accesibility.name} </Link> </li>
                        <li>
                            <ReactFlagsSelect
                                defaultCountry={lang === "es" ? "MX" : "US"}
                                className="menu-flag"
                                countries={["MX", "US"]}
                                placeholder="Idioma"
                                showSelectedLabel={false}
                                showOptionLabel={false}
                                selectedSize={18}
                                optionsSize={18}
                                onSelect={changeLanguage}
                            />
                        </li>
                    </ul>
            </BackgroundImage>
            <div className="city-software-tile">
                <span>Sitio web desarrollado por</span>
                <a href="https://www.citysoftware.dev">{`{CS} CitySoftware`}</a>
            </div>
        </footer>
    );
};

export default Footer;